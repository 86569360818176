// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-benefits-of-time-management-js": () => import("./../../../src/pages/benefits-of-time-management.js" /* webpackChunkName: "component---src-pages-benefits-of-time-management-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-features-expenses-js": () => import("./../../../src/pages/features/expenses.js" /* webpackChunkName: "component---src-pages-features-expenses-js" */),
  "component---src-pages-features-index-js": () => import("./../../../src/pages/features/index.js" /* webpackChunkName: "component---src-pages-features-index-js" */),
  "component---src-pages-features-invoicing-js": () => import("./../../../src/pages/features/invoicing.js" /* webpackChunkName: "component---src-pages-features-invoicing-js" */),
  "component---src-pages-features-reporting-js": () => import("./../../../src/pages/features/reporting.js" /* webpackChunkName: "component---src-pages-features-reporting-js" */),
  "component---src-pages-freelance-time-tracking-js": () => import("./../../../src/pages/freelance-time-tracking.js" /* webpackChunkName: "component---src-pages-freelance-time-tracking-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-legal-tos-js": () => import("./../../../src/pages/legal/tos.js" /* webpackChunkName: "component---src-pages-legal-tos-js" */),
  "component---src-pages-poor-time-management-js": () => import("./../../../src/pages/poor-time-management.js" /* webpackChunkName: "component---src-pages-poor-time-management-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-time-management-101-js": () => import("./../../../src/pages/time-management-101.js" /* webpackChunkName: "component---src-pages-time-management-101-js" */),
  "component---src-pages-time-management-activities-js": () => import("./../../../src/pages/time-management-activities.js" /* webpackChunkName: "component---src-pages-time-management-activities-js" */),
  "component---src-pages-time-management-goals-js": () => import("./../../../src/pages/time-management-goals.js" /* webpackChunkName: "component---src-pages-time-management-goals-js" */),
  "component---src-pages-time-management-habits-js": () => import("./../../../src/pages/time-management-habits.js" /* webpackChunkName: "component---src-pages-time-management-habits-js" */),
  "component---src-pages-time-management-strategies-js": () => import("./../../../src/pages/time-management-strategies.js" /* webpackChunkName: "component---src-pages-time-management-strategies-js" */),
  "component---src-pages-time-management-tools-js": () => import("./../../../src/pages/time-management-tools.js" /* webpackChunkName: "component---src-pages-time-management-tools-js" */),
  "component---src-pages-timesheet-app-js": () => import("./../../../src/pages/timesheet-app.js" /* webpackChunkName: "component---src-pages-timesheet-app-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

