// The minimum width in pixels for each breakpoint size
export const MEDIA_BREAKPOINTS = [
	{size: "xxs", minWidth: 0},
	{size: "xs", minWidth: 320},
	{size: "sm", minWidth: 576},
	{size: "md", minWidth: 768},
	{size: "lg", minWidth: 992},
	{size: "xl", minWidth: 1200},
	{size: "xxl", minWidth: 1440},
	{size: "xxxl", minWidth: 1600},
];
