import {MEDIA_BREAKPOINTS} from "./MediaUtil";

const keys = MEDIA_BREAKPOINTS.map(breakpoint => breakpoint.size);
const firstKey = keys[0];

const breakpointValues = {};
MEDIA_BREAKPOINTS.forEach(breakpoint => {
	const {size, minWidth} = breakpoint;
	breakpointValues[size] = minWidth;
});

const unit = 'px';

const breakpoints = {};
MEDIA_BREAKPOINTS.forEach(breakpoint => {
	let {size, minWidth} = breakpoint;
	breakpoints[size] = minWidth;
});

function getBreakpointValue(key) {
	let val = breakpoints[key];
	if(val === undefined) {
		console.error("attempted to get undefined breakpoint '" + key + "'");
		val = breakpoints[firstKey];
	}
	return val;
}

function up(key) {
	const value = getBreakpointValue(key);
	return `@media (min-width:${value}${unit})`;
}

function down(key) {
	const endIndex = keys.indexOf(key) + 1;
	const upperbound = getBreakpointValue(keys[endIndex]);

	if (endIndex === keys.length) {
		return up(firstKey);
	}

	return `@media (max-width:${upperbound - 1}${unit})`;
}

function between(start, end) {
	const endIndex = keys.indexOf(end) + 1;

	if (endIndex === keys.length) {
		return up(start);
	}

	return `@media (min-width:${getBreakpointValue(start)}${unit}) and (max-width:${getBreakpointValue(keys[endIndex]) - 1}${unit})`;
}

function only(key) {
	return between(key, key);
}

function width(key) {
	return getBreakpointValue(key);
}

const customBreakpointsTheme = {
	keys,
	values: breakpointValues,
	up,
	down,
	between,
	only,
	width,
};

export default customBreakpointsTheme;
