import React from "react";
import PropTypes from 'prop-types';
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import customBreakpointsTheme from "./customBreakpoints";
import {amber, pink} from '@material-ui/core/colors'
import {Helmet} from "react-helmet";
import {lighten, useTheme} from "@material-ui/core/styles";
import ls from "local-storage";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const darkModeSupported = false;

const lightContentBg = "rgba(255, 255, 255, 0.95)";
const darkContentBg = 'rgba(18,18,18, 0.95)';

const useBodyStyles = makeStyles(theme => {
	const isDark = theme.palette.type === "dark";
	return {
		bodyBg: {
			backgroundColor: isDark ? "#1c2023" : "#fbfbfb",
			// background: 'url("/public/images/bg/donald-giannatti-671274-unsplash.jpg") 0% 0% / cover no-repeat fixed content-box padding-box ' + (isDark ? "#1c2023" : "#eee"),
			"@media (hover:none)": {
				backgroundRepeat: "repeat",
				backgroundAttachment: "initial", // mobile devices don't usually support "fixed", so "initial" will use "scroll"
				minHeight: "700px", // This is so the image doesn't repeat too quickly on narrow screens, but the downside is it may cause unnecessary empty space at the bottom of short views
			},
		},
	};
}, {name: "Body"});

const darkModeSurfaceLightTable = [0, 5, 7, 8, 9, 9, 11, 11, 12, 12, 12, 12, 14, 14, 14, 14, 15, 15, 15, 15, 15, 15, 15, 15, 16];

function getDarkElevationRules() {
	const style = {};
	for(let i = 1; i < darkModeSurfaceLightTable.length; i++) {
		style['elevation' + i] = {
			backgroundColor: getDarkElevationBackgroundColor(i),
		}
	}
	return style;
}

export function getDarkElevationBackgroundColor(elevationIndex) {
	elevationIndex = Math.max(0, Math.min(darkModeSurfaceLightTable.length - 1, elevationIndex));
	return lighten(darkContentBg, darkModeSurfaceLightTable[elevationIndex] / 100);
}

// TODO: deep merge to reduce duplication of outlined field styles in overrides

function buildInputAutofillStyle(theme) {
	const isDark = theme.palette.type === "dark";
	return {
		'&:-webkit-autofill': {
			// Since iOS Safari is not honoring the WebkitBoxShadow, but is honoring the transition delay, delay the
			// color changes as if they're not going to happen so that there's no banana yellow background with
			// unreadable white text in dark theme.
			// https://mariusschulz.com/blog/how-to-remove-webkits-banana-yellow-autofill-background
			// https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete/32505530#32505530
			WebkitTransition: isDark ? "color 9999s ease-out, background-color 9999s ease-out" : null,
			WebkitTransitionDelay: isDark ? "9999s" : null,
		},
		'&::-webkit-credentials-auto-fill-button': {
			backgroundColor: theme.palette.primary.main,
		},
		'&::-webkit-contacts-auto-fill-button': {
			backgroundColor: theme.palette.primary.main,
		}
	}
}

const buildScrollbarStyle = (thumbBg, bg, borderColor) => `
	::-webkit-scrollbar {
		width: 15px;
		height: 15px;
	}
	::-webkit-scrollbar-track {
		background: ` + bg + `;
	}
	::-webkit-scrollbar-thumb {
		background: ` + thumbBg + `;
		border-radius: 8px;
		border: 3px solid ` + borderColor + `;
	}
`;
const scrollbarLightStyle = buildScrollbarStyle("#c2c2c2", "#fafafa", "#fafafa");
const scrollbarDarkStyle = buildScrollbarStyle("#676767", "rgba(0,0,0,0.3)", "rgba(20,20,20,1.0)");

function makeTheme(paletteType, overrides = {}) {
	const t = createMuiTheme({
		palette: {
			type: paletteType,
			...(paletteType === 'dark' ? {
				primary: amber,
				secondary: {
					dark: pink[300],
					main: pink[200],
					light: pink[100],
				},
				background: {
					paper: darkContentBg,
				}
			} : {
				primary: {
					main: "#3636ec",
				},
				secondary: {
					main: "#518",
				},
				text: {
					hint: 'rgba(0, 0, 0, 0.4)',
				},
				background: {
					default: "#fbfbfb",
				}
			}),
			...overrides.palette,
		},
		breakpoints: customBreakpointsTheme,
		typography: {
			fontSize: 18,
			button: {
				textTransform: 'none',
			},
			h1: { fontWeight: "400" },
			h2: { fontWeight: "400" },
			h3: { fontWeight: "400" },
			h4: { fontWeight: "400" },
			h5: { fontWeight: "700" },
			h6: { fontWeight: "700" },
		},
		shape: {
			borderRadius: 6,
		},
	});

	// Font sizes
	const headerFontSizes = [
		[34.8, 30],
		[26.2, 26.2],
		[22.4, 22.4],
		[20, 20],
		[18, 18],
		[16, 16],
	];
	headerFontSizes.forEach(([fontSize, fontSizeSmDown], idx) => {
		shallowMerge(t.typography, "h" + (idx + 1), {
			[t.breakpoints.down('sm')]: {fontSize: fontSizeSmDown},
			fontSize: t.typography.pxToRem(fontSize),
		});
	});

	// Material-UI Picker overrides
	t.overrides = {
		...t.overrides,
		MuiPickersStaticWrapper: {
			staticWrapperRoot: {
				backgroundColor: "transparent",
			}
		},
		MuiPickersCalendarHeader: {
			iconButton: {
				backgroundColor: "transparent",
			}
		},
	};

	// Material-UI overrides
	t.overrides = {
		...t.overrides,
		MuiTypography: {
			paragraph: {
				marginBottom: 14,
			},
		},
		MuiButton: {
			text: {
				// textDecoration: "underline",
				// '&:hover': {
				// 	textDecoration: "underline",
				// },
				textTransform: "uppercase",
			},
			sizeLarge: {
				fontSize: t.typography.pxToRem(18),
			},
			colorInherit: {
				'&$disabled': {
					color: 'inherit',
					opacity: 0.38,
				},
			},
		},
		MuiIconButton: {
			sizeSmall: {
				padding: 6,
			},
			edgeStart: {
				'$sizeSmall&': {
					marginLeft: -6,
				},
			},
			edgeEnd: {
				'$sizeSmall&': {
					marginRight: -6,
				},
			},
		},
		MuiTab: {
			root: {
				textTransform: "uppercase",
				[t.breakpoints.up('sm')]: {
					minWidth: "72px",
					fontSize: t.typography.pxToRem(16),
					padding: "6px 12px",
				},
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: "16px",
				marginRight: "20px",
			},
		},
		MuiTableCell: {
			head: {
				fontWeight: t.typography.fontWeightBold,
				// backgroundColor: t.palette.type === "light" ? t.palette.primary.main : darken(darkContentBg, 0.05),
				// color: t.palette.type === "light" ? t.palette.primary.contrastText : t.palette.text.primary,
			},
			sizeSmall: {
				padding: "6px 16px",
				'&$paddingCheckbox': {
					padding: "0 0 0 8px",
				},
			},
		},
	};

	if (t.palette.type === "light") {
		t.overrides = {
			...t.overrides,
			MuiPaper: {
				root: {
					backgroundColor: lightContentBg,
				}
			},
			MuiOutlinedInput: {
				root: {
					'&:hover $notchedOutline': {
						borderColor: "rgba(0, 0, 0, 0.87)",
					},
				},
				notchedOutline: {
					borderColor: "rgba(0, 0, 0, 0.38)"
				},
				input: {
					...buildInputAutofillStyle(t),
				},
			},
		};
	} else {
		t.overrides = {
			...t.overrides,
			MuiPaper: {
				...getDarkElevationRules(),
			},
			MuiOutlinedInput: {
				input: {
					...buildInputAutofillStyle(t),
				},
			}
		}
	}

	return t;
}

function shallowMerge(obj, propertyName, newProps) {
	obj[propertyName] = {
		...obj[propertyName],
		...newProps,
	};
}

export const ThemeContext = React.createContext({});

function normalizeTheme(theme) {
	return (theme === "light" || theme === "dark" || theme === "auto") ? theme : "auto";
}

export function CustomThemeProvider({children}) {
	const [themeChoice, setThemeChoice] = React.useState(normalizeTheme(ls("theme")));

	const browserPrefersLight = useMediaQuery("(prefers-color-scheme: light)");

	let effectiveThemeChoice = themeChoice !== "auto" ? themeChoice : browserPrefersLight ? "light" : "dark";
	if (!darkModeSupported) {
		effectiveThemeChoice = "light";
	}

	function setPaletteTypeAndSave(newPaletteType) {
		const newPaletteTypeNormalized = normalizeTheme(newPaletteType);
		ls("theme", newPaletteTypeNormalized);
		setThemeChoice(newPaletteTypeNormalized);
	}

	React.useEffect(() => {
		const updateTheme = newTheme => setThemeChoice(normalizeTheme(newTheme));
		ls.on("theme", updateTheme);
		return () => ls.off("theme", updateTheme);
	}, []);

	const theme = makeTheme(effectiveThemeChoice);

	return (
		<ThemeProvider theme={theme}>
			<ThemeContext.Provider value={{
				paletteType: themeChoice,
				setPaletteType: setPaletteTypeAndSave,
			}}>
				{/*<BodyContent applyBodyClass={applyBodyClass}>*/}
					{children}
				{/*</BodyContent>*/}
			</ThemeContext.Provider>
		</ThemeProvider>
	);
}

function BodyContent(props) {
	let {applyBodyClass, children} = props;

	const classes = useBodyStyles();
	const theme = useTheme();
	const isLight = theme.palette.type === 'light';
	return (
		<React.Fragment>
			<Helmet>
				{applyBodyClass && (
					<body className={classes.bodyBg}/>
				)}
				{/*Only mess with the scrollbars if we can support dark mode since this causes the scrollbars to always be visible on Desktop, even if using e.g. a magic mouse on macOS Safari*/}
				{darkModeSupported && (
					<style type="text/css">{isLight ? scrollbarLightStyle : scrollbarDarkStyle}</style>
				)}
			</Helmet>
			{children}
		</React.Fragment>
	);
}
BodyContent.propTypes = {
	applyBodyClass: PropTypes.bool,
	children: PropTypes.any,
};

export function ForceLightTheme({children}) {
	const theme = makeTheme("light");
	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	);
}

export function ForceDarkTheme({children}) {
	const theme = makeTheme("dark");
	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	);
}

export function ForceHeadlineDarkTheme({children}) {
	const overrides = {
		palette: {
			primary: amber,
			secondary: {
				dark: pink[300],
				main: pink[200],
				light: pink[100],
			},
		},
	};
	const theme = makeTheme("dark", overrides);
	return (
		<ThemeProvider theme={theme}>
			{children}
		</ThemeProvider>
	);
}
