import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CustomThemeProvider } from '../../src/reused-libraries/materialTheme'
import './../../src/components/FontAwesomeLibrary'
import { makeStyles } from '@material-ui/core/styles'

export default function TopLayout(props) {
	let {children} = props;
	return (
		<React.Fragment>
			<Helmet defer={false}>
				<meta name="viewport" content="initial-scale=1, width=device-width, shrink-to-fit=no" />
				<link href="https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,700i&display=swap" rel="stylesheet"/>
			</Helmet>
			<CustomThemeProvider>
				<CssBaseline/>
				{/*<DebugHeaderTags/>*/}
				<DefaultHeaderCss/>
				{children}
			</CustomThemeProvider>
		</React.Fragment>
	);
}
TopLayout.propTypes = {
	children: PropTypes.node,
};

const useDefaultHeaderCssStyles = makeStyles(theme => ({
	// Apply header styling even if just using e.g. <h1/> instead of <Typography component="h1"/>
	"@global": ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((a, c) => {
		a[c] = {...theme.typography[c]};
		return a;
	}, {}),
}), {name: "DefaultHeaderCss"});

/** Apply header styling even if just using e.g. <h1/> instead of <Typography component="h1"/> */
function DefaultHeaderCss({children = null}) {
	useDefaultHeaderCssStyles();
	return (<React.Fragment>{children}</React.Fragment>);
}

const useDebugHeaderTagsStyles = makeStyles(theme => ({
	"@global": ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].reduce((a, c) => {
		a[c + '::after'] = {
			content: "': " + c + "'",
			color: "magenta",
			display: "inline",
			visibility: "visible",
		};
		return a;
	}, {}),
}), {name: "DebugHeaderTags"});

/** Rendering this component will prefix header h1, h2, etc. tags with a magenta prefix for easy visibility. */
function DebugHeaderTags({children = null}) {
	useDebugHeaderTagsStyles();
	return (<React.Fragment>{children}</React.Fragment>);
}
