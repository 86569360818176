import { library, config } from '@fortawesome/fontawesome-svg-core'
import { faBriefcase, faCalculator, faCircle, faCheck, faCloudUploadAlt, faCoins, faFileInvoice, faGlobeAmericas, faMobileAlt, faQuoteLeft, faTable, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faFacebookSquare, faLinkedin, faTwitterSquare, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons'

// Fix FontAwesome big icon while mounting: https://stackoverflow.com/questions/56334381/why-my-font-awesome-icons-are-being-displayed-big-at-first-and-then-updated-to-t#answer-59429852
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;

library.add(
	// -- fas --
	faBriefcase,
	faCalculator,
	faCircle,
	faCheck,
	faCloudUploadAlt,
	faCoins,
	faFileInvoice,
	faGlobeAmericas,
	faMobileAlt,
	faQuoteLeft,
	faTable,
	faUser,
	faUsers,
	// -- far --
	faClock,
	// -- fab --
	faFacebookSquare,
	faLinkedin,
	faTwitterSquare,
	faYoutubeSquare,
);
